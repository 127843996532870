<template>
  <div class="popup__con_inner" style="max-height: none">
    <div class="privacy-wrap">
      <div class="policy-box">
        <p class="txt">
          ○ 주식회사 카이아이(이하 '회사')는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고
          있습니다.
        </p>
        <p class="txt">
          ○ 개인정보처리방침은 정부의 법령이나 지침의 변경에 의해서, 또는 나은 서비스의 제공을 위해서 그 내용이 변경될 수 있습니다. 이 경우 회사는 웹사이트(또는 개별공지)를 통하여 공지할 예정입니다.
        </p>
        <p class="txt last-txt">
          ○ 본 방침은
          <span class="underline">2021년 01월 01일부터 시행</span>
          됩니다.
        </p>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">1. 개인정보의 처리 목적</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 예정입니다.</p>
          </li>
          <li class="list">
            <p class="list-txt seq-txt">가. 홈페이지 회원가입 및 관리</p>
            <p class="list-txt">
              회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인 확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시
              법정대리인 동의 여부 확인, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
            </p>
          </li>
          <li class="list">
            <p class="list-txt seq-txt">나. 서비스의 개선에 관한 사무 처리</p>
            <p class="list-txt">회원의 신원 확인, 회원의 요청사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등을 목적으로 개인정보를 처리합니다.</p>
          </li>
          <li class="list">
            <p class="list-txt seq-txt">다. 재화 또는 서비스 제공</p>
            <p class="list-txt">서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 신규서비스 요소의 발굴 및 기존서비스개선, 본인인증 등을 목적으로 개인정보를 처리합니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">2. 개인정보의 제3자 제공에 관한 사항</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">회사는 정보 주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</p>
            <p class="list-txt">- 수집방법 : 홈페이지, 서면양식, 전화/팩스, 생성정보 수집 툴을 통한 수집 등</p>
            <p class="list-txt">- 보유목적 : 서비스 운영 및 기록 보존</p>
            <p class="list-txt">- 제공받는자 : 치과의사회, 보건소, 학교, 지역아동센터</p>
          </li>
        </ul>
        <div class="board__box">
          <table class="comm__table">
            <thead>
              <tr>
                <th><p class="commp">순번</p></th>
                <th><p class="commp">개인정보파일의 명칭</p></th>
                <th><p class="commp">보유근거</p></th>
                <th><p class="commp">개인정보파일에 기록되는 개인정보의 항목</p></th>
                <th><p class="commp">보유기간</p></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><p class="commp">1</p></td>
                <td><p class="commp">진료기록부에 기재된 구강질환에 관한 사항</p></td>
                <td><p class="commp">의료법 시행규칙</p></td>
                <td rowspan="4">
                  <p class="commp">성명(국문), 성별, 생년월일, 휴대폰번호, 구강검진(분석)결과</p>
                </td>
                <td><p class="commp">10년</p></td>
              </tr>
              <tr>
                <td><p class="commp">2</p></td>
                <td>
                  <p class="commp">소비자의 불만 또는 분쟁처리에 관한 기록</p>
                </td>
                <td>
                  <p class="commp">전자상거래 등에서의 소비자보호에 관한 법률</p>
                </td>
                <td><p class="commp">3년</p></td>
              </tr>
              <tr>
                <td><p class="commp">3</p></td>
                <td>
                  <p class="commp">본인 확인에 관한 기록</p>
                </td>
                <td>
                  <p class="commp">정보통신망 이용촉진 및 정보보호 등에 관한 법률</p>
                </td>
                <td><p class="commp">6개월</p></td>
              </tr>
              <tr>
                <td><p class="commp">4</p></td>
                <td>
                  <p class="commp">방문에 관한 기록</p>
                </td>
                <td>
                  <p class="commp">통신비밀보호법</p>
                </td>
                <td><p class="commp">2년</p></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">3. 개인정보의 처리 및 보유 기간</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">① 회사는 법령에 따른 개인정보와 이용기간 또는 정보주체로부터 수집 시에 동의 받은 개인정보와 이용기간 내에서 개인정보를 처리·보유합니다.</p>
            <p class="list-txt">
              ② 개인정보는 홈페이지 회원가입 및 관리, 재화 또는 서비스 제공, 마케팅 및 광고에의 활용, 덴티아이 서비스 개선에 관한 사무 처리를 위하여, 회원이 개인정보의 수집·이용에 동의한 날로부터 상기
              2.항의 기간과 동일하게 보유·이용됩니다.
            </p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">4. 개인정보처리 위탁</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">① 회사는 원활한 개인정보 업무처리를 위하여 개인정보 처리업무를 위탁할 수 있습니다.</p>
            <p class="list-txt">
              ② 회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한
              사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여
              공개하도록 하겠습니다. 회사는 현재 개인정보를 처리업무를 위해 위탁하고 있지 않습니다.
            </p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">5. 정보주체의 권리, 의무 및 그 행사방법</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.</p>
          </li>
          <li class="list">
            <p class="list-txt">① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보보호 관련 권리를 행사할 수 있습니다.</p>
            <p class="list-txt">1) 개인정보 열람요구</p>
            <p class="list-txt">2) 오류 등이 있을 경우 정정 요구</p>
            <p class="list-txt">3) 삭제요구</p>
            <p class="list-txt">4) 처리정지 요구</p>
          </li>
          <li class="list">
            <p class="list-txt">
              ② 제1항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 서비스홈페이지(
              <a href="https://dentii.kai-i.com/" target="_blank">https://dentii.kai-i.com/</a>
              )에서 이에 대해 지체 없이 조치하겠습니다.
            </p>
          </li>
          <li class="list">
            <p class="list-txt">
              ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 서비스 홈페이지(
              <a href="https://dentii.kai-i.com/" target="_blank">https://dentii.kai-i.com/</a>
              )에서 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
            </p>
          </li>
          <li class="list">
            <p class="list-txt">
              ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야
              합니다.
            </p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">6. 처리하는 개인정보의 항목 작성</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">① 회사는 홈페이지 회원가입 및 서비스 이용과 관련하여 다음의 개인정보 항목을 처리하고 있습니다.</p>
            <p class="list-txt">- 성명(국문), 이메일, 이메일 비밀번호, 성별, 생년월일, 휴대폰번호, 회원의 구강 건강검진결과 및 관련 분석결과</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">7. 개인정보의 파기</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체 없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.</p>
            <p class="list-txt">
              - 파기 절차 : 파기절차이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때,
              DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
            </p>
            <p class="list-txt">
              - 파기 기한 : 파기기한이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그
              개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
            </p>
            <p class="list-txt">- 파기 방법 : 전자적 파일 형태의 정보는 파기방법으로 기록을 재생할 수 없는 기술적 방법을 사용합니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">8. 개인정보의 안전성 확보 조치</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.</p>
          </li>
          <li class="list">
            <p class="list-txt">1) 정기적인 자체 감사 실시</p>
            <p class="list-txt">개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.</p>
          </li>
          <li class="list">
            <p class="list-txt">2) 개인정보 취급 직원의 최소화 및 교육</p>
            <p class="list-txt">개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.</p>
          </li>
          <li class="list">
            <p class="list-txt">3) 내부관리계획의 수립 및 시행</p>
            <p class="list-txt">개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</p>
          </li>
          <li class="list">
            <p class="list-txt">4) 해킹 등에 대비한 기술적 대책</p>
            <p class="list-txt">
              회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고
              기술적/물리적으로 감시 및 차단하고 있습니다.
            </p>
          </li>
          <li class="list">
            <p class="list-txt">5) 개인정보의 암호화</p>
            <p class="list-txt">
              이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을
              사용하고 있습니다.
            </p>
          </li>
          <li class="list">
            <p class="list-txt">6) 접속기록의 보관 및 위변조 방지</p>
            <p class="list-txt">개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.</p>
          </li>
          <li class="list">
            <p class="list-txt">7) 개인정보에 대한 접근 제한</p>
            <p class="list-txt">
              개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
              접근을 통제하고 있습니다.
            </p>
          </li>
          <li class="list">
            <p class="list-txt">8) 문서보안을 위한 잠금장치 사용</p>
            <p class="list-txt">개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.</p>
          </li>
          <li class="list">
            <p class="list-txt">9) 비인가자에 대한 출입 통제</p>
            <p class="list-txt">개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">9. 개인정보 보호책임자 작성</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">
              ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
            </p>
          </li>
          <div class="board__box">
            <table class="comm__table">
              <thead>
                <tr>
                  <th><p class="commp">구분</p></th>
                  <th><p class="commp">부서명</p></th>
                  <th><p class="commp">성명</p></th>
                  <th><p class="commp">연락처</p></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><p class="commp">개인정보 보호책임자</p></td>
                  <td><p class="commp">(주)카이아이컴퍼니</p></td>
                  <td><p class="commp">정호정</p></td>
                  <td>
                    <p class="commp">전화 : 1670-2628</p>
                    <p class="commp">이메일 :i-contact@kai-i.com</p>
                    <p class="commp">FAX : 02-2135-9355</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="commp">분야별 개인정보 관리 책임자</p>
                  </td>
                  <td><p class="commp">시스템 정책팀</p></td>
                  <td><p class="commp">양진홍</p></td>
                  <td>
                    <p class="commp">전화 : 02-6264-7167</p>
                    <p class="commp">이메일 :realred3@kai-i.com</p>
                    <p class="commp">FAX : 02-2135-9355</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="commp">분야별 개인정보 보호담당자</p>
                  </td>
                  <td><p class="commp">시스템 정책팀</p></td>
                  <td><p class="commp">양진홍</p></td>
                  <td>
                    <p class="commp">전화 : 02-6264-7167</p>
                    <p class="commp">이메일 :realred3@kai-i.com</p>
                    <p class="commp">FAX : 02-2135-9355</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <li class="list">
            <p class="list-txt">
              ② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
              회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
            </p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">10. 개인정보 열람청구</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">
              아래의 기관은 주식회사 카이아이와는 별개의 기관으로서, 당사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
            </p>
          </li>
          <li class="list">
            <p class="list-txt">▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</p>
            <p class="list-txt">- 소관업무 : 개인정보 침해사실 신고, 상담 신청</p>
            <p class="list-txt">
              - 홈페이지 :
              <a href="https://privacy.kisa.or.kr/" target="_blank">https://privacy.kisa.or.kr/</a>
            </p>
            <p class="list-txt">- 전화 : (국번없이) 118</p>
            <p class="list-txt">- 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</p>
          </li>
          <li class="list">
            <p class="list-txt">▶ 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)</p>
            <p class="list-txt">- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</p>
            <p class="list-txt">
              - 홈페이지 :
              <a href="https://privacy.kisa.or.kr/" target="_blank">https://privacy.kisa.or.kr/</a>
            </p>
            <p class="list-txt">- 전화 : (국번없이) 118</p>
            <p class="list-txt">- 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</p>
          </li>
          <li class="list">
            <p class="list-txt">
              ▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (
              <a href="https://spo.go.kr/" target="_blank">https://spo.go.kr/</a>
              )
            </p>
          </li>
          <li class="list">
            <p class="list-txt">
              ▶ 경찰청 사이버범죄수사단 : 1566-0112 (
              <a href="https://cyberbureau.police.go.kr/" target="_blank">https://cyberbureau.police.go.kr/</a>
              )
            </p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">11. 개인정보 처리방침 변경</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">
              이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지하여 회원의 동의를 받을
              것입니다.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    Gitple('shutdown');
  },
};
</script>
