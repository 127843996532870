var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dash_board_content"},[(_vm.districtCodeFirst === 'C')?_c('div',{staticClass:"dash__comm_set row mt30",staticStyle:{"display":"flex"}},[_vm._l((_vm.gyeonggiRowData),function(data,Object,index){return [(index <= 1)?_c('chart-table-container',{key:index,attrs:{"title":data.title,"content-type":data.contentType,"data":data.values,"chart-type":"PieChart","custom-class":"dash__comm_box col_span_2"}}):_vm._e()]})],2):_c('div',{staticClass:"dash__comm_set row mt30"},_vm._l((_vm.rowData),function(data,object,idx){return _c('v-item-container',{key:idx,class:{ hideDiv: (_vm.districtCodeFirst !== 'A' || _vm.businessDivisionStatus !== 'C') && idx === 1, col_2: _vm.districtCodeFirst !== 'A' || _vm.businessDivisionStatus !== 'C' },attrs:{"title":data.title,"toggle-label":_vm.getToggleData(data.values),"class-name":'dash__comm_box' + (idx + 1) + ' inner--left',"button":"바로가기"},scopedSlots:_vm._u([_vm._l((data.values),function(info,key,_idx){return {key:key,fn:function(){return [(info.list)?_c('v-item-box',{key:_idx,attrs:{"data":info.list.map(function (item) { return ({
              head: ((item.studentName) + " (" + (item.studentBirth || '-') + ")"),
              body: item.userOrganTargetName ? item.userOrganTargetName : '없음',
              foot: idx === 2 ? _vm.numberWithCommas(item.totalBillPrice) : item.moddate ? item.moddate : '미작성',
            }); }),"icon":Object.assign({}, {head: require('@/assets/images/common/ic_person_s.png')},
            (idx === 2 ? { foot: require('@/assets/images/common/ic_won_s.png') } : null)),"msg":data.message}}):_vm._e()]},proxy:true}})],null,true)})}),1),_c('div',{staticClass:"dash__comm_set row mt40"},[(_vm.districtCodeFirst === 'C')?[_c('chart-table-container',{attrs:{"title":_vm.gyeonggiRowData['businessDivisionCount'].title,"content-type":_vm.gyeonggiRowData['businessDivisionCount'].contentType,"data":_vm.gyeonggiRowData['businessDivisionCount'].values,"chart-type":"ColumnChart","custom-class":"dash__comm_box inner--left"}}),_c('v-item-container',{attrs:{"button":"바로가기","title":"공지사항","toggle-label":_vm.getToggleData(_vm.gyeonggiNotice.values, false),"class-name":"dash__comm_box4 inner--left"},scopedSlots:_vm._u([_vm._l((_vm.gyeonggiNotice.values),function(info,key){return {key:key,fn:function(){return [_c('notice-box',{key:key,attrs:{"data":info.list,"title":info.title,"onrowclick":function (item) {
                info.title.includes('문의')
                  ? _vm.$router.push({ path: ("" + (info.rowLink)), query: { inquiryId: item.inquiryId } })
                  : info.title === '자주하는 질문'
                  ? _vm.$router.push({ path: ("" + (info.rowLink)), query: { faqId: item.faqId } })
                  : _vm.$router.push({ path: ("" + (info.rowLink)), query: { noticeBoardId: item.noticeBoardId } });
              },"options":info.options}})]},proxy:true}})],null,true)})]:[_c('v-item-container',{attrs:{"title":"사업현황","toggle-label":_vm.getToggleData(_vm.billChart.values, false),"class-name":"dash__comm_box4 inner--left"},scopedSlots:_vm._u([_vm._l((_vm.billChart.values),function(info,key,idx){return {key:key,fn:function(){return [(info.list)?_c('div',{key:idx,staticClass:"m_inner row"},[(info.list.length)?_c('GChart',{attrs:{"type":"ColumnChart","options":_vm.chartProps['options'],"data":_vm.chartData(info)}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.billChart.message)}})],1):_vm._e()]},proxy:true}})],null,true)}),(_vm.districtCodeFirst === 'A')?_c('v-item-container',{attrs:{"button":"바로가기","title":"공지사항","toggle-label":_vm.getToggleData(_vm.seoulNotice.values, false),"class-name":"dash__comm_box4 inner--left"},scopedSlots:_vm._u([_vm._l((_vm.seoulNotice.values),function(info,key){return {key:key,fn:function(){return [_c('notice-box',{key:key,attrs:{"data":info.list,"title":info.title,"onrowclick":function (item) {
                info.title.includes('문의')
                  ? _vm.$router.push({ path: ("" + (info.rowLink)), query: { inquiryId: item.inquiryId } })
                  : info.title === '자주하는 질문'
                  ? _vm.$router.push({ path: ("" + (info.rowLink)), query: { faqId: item.faqId } })
                  : _vm.$router.push({ path: ("" + (info.rowLink)), query: { noticeBoardId: item.noticeBoardId } });
              },"options":info.options}})]},proxy:true}})],null,true)}):_c('v-list-box',{attrs:{"title":"공지사항","data":_vm.noticeList,"button":{
          label: '바로가기',
          action: function () {
            _vm.$router.push('/hospital/board/notice/list');
          },
        },"onrowclick":function (item) {
            _vm.$router.push({ path: '/hospital/board/notice/contents', query: { noticeBoardId: item.noticeBoardId } });
          },"options":{
          baseIcon: require('@/assets/images/common/ic_loudspeaker.png'),
          datapick: true,
          pickEffect: 'New',
          pickCond: function (item) {
            var target = Date.parse(item.regdate);
            return target > _vm.pastDate.getTime() && target < _vm.nowDate.getTime();
          },
        }}})],_c('v-manual-box',{attrs:{"title":"사용자 매뉴얼","data":_vm.manualList,"data-img":_vm.manualImg}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }