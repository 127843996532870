<template>
  <div class="popup_content">
    <v-popuptitle message="울산광역시교육청 검진비용 정산 안내" imgsrc="sub/ic_popup.png"></v-popuptitle>
    <div class="popup__con_inner tac">
      <div class="popup__item">
        <div class="popup__box">
          <p class="popup__text">
            2024.4.15. ~ 5.31. 비용 청구 건에 대해 1차 정산을 실시하여
            <br />
            2024.6.26.(수) 병원별 등록 계좌로 지급 완료되었음을 알려드립니다.
          </p>
          <p class="popup__sub mt20">
            각 병원에서는 덴티아이 &gt; 비용청구서 &gt; 청구금액에서 "처리완료" 금액과 실제 입금 금액을 확인하시고
            <br />
            이상이 있는 경우 052-210-5583으로 연락주시기 바랍니다.
          </p>
          <p class="popup__sub mt30 fw500">
            ※ 당초 검진비용을 2회(9월 중순, 12월 중순) 정산하여 지급할 계획이었으나
            <br />
            지급 횟수를 1회 추가하여 <strong>총 3회 지급</strong>할 계획입니다.
          </p>
          <div class="popup__info mt15">
            <table>
              <colgroup>
                <col width="120" />
                <col width="200" />
                <col width="*" />
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>청구 시기</th>
                  <th>지급 시기</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>1차 정산</th>
                  <td>4.15 ~ 5.31 청구 건</td>
                  <td>2024.6.26.(수) 지급</td>
                </tr>
                <tr>
                  <th>2차 정산</th>
                  <td>6.1 ~ 8.31 청구 건</td>
                  <td>2024.9.13.(금) 지급 예정</td>
                </tr>
                <tr>
                  <th>3차 정산</th>
                  <td>9.1 ~ 11.30 청구 건</td>
                  <td>2024.12.13.(금) 지급 예정</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="popup__close">
        <a href="javascript:;" @click="customClose('2024-07-31')">다시 보지 않기</a>
      </div>
    </div>
  </div>
</template>

<script>
import popupTitle from '@/components/popup/PopupTitle.vue';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    'v-popuptitle': popupTitle,
  },
  computed: {
    ...mapState('user', ['userOrganTargetType']),
    ...mapState('status', ['userOrganTargetTypeToPath']),
  },
  methods: {
    ...mapActions('popup', ['customClose']),
  },
};
</script>

<style scoped>
.popup__item {
  padding: 7px 80px 30px 80px;
}
.popup__box {
  padding: 30px 0;
  background: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  text-align: center;
}
.popup__text {
  line-height: 30px;
  font-size: 21px;
  font-weight: 700;
  color: #1c1c1c;
}
.popup__sub {
  line-height: 23px;
  font-size: 16px;
  color: #191919;
}
.popup__info {
  padding: 0 100px;
}
.popup__info table {
  border: 1px solid #cacaca;
}
.popup__info th,
.popup__info td {
  padding: 5px 18px;
  border-left: 1px solid #cacaca;
  line-height: 22px;
  font-size: 16px;
  color: #191919;
}
.popup__info th {
  font-weight: 700;
}
.popup__info td {
  text-align: left;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.popup__info thead th {
  background-color: #f0f0f0;
}
.popup__info tbody th,
.popup__info tbody td {
  border-top: 1px solid #cacaca;
}
.popup__close {
  float: right;
  margin-right: 10px;
}
.popup__close a {
  padding: 10px 25px;
  margin-right: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #737373;
  vertical-align: middle;
}
</style>
