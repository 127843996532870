<template>
  <div class="sub__content form__ver_1 bg_white mt30">
    <v-detailed-search :cmp="cmp" divclass="mt15" @search="search"></v-detailed-search>
    <div class="comm__line mt20"></div>
    <v-list title="학생" :cmp="cmp" divclass="mt30" :table="table" :pager="pager">
      <a
        v-if="(userOrganTargetType === 'HC' || districtCodeFirst === 'C' || adminOrganType === 'AD') && districtCodeFirst !== 'G'"
        href="javascript:;"
        class="comm__blue_btn btn_m_size2 d_block wid130 commp"
        @click="studentEnrollment"
      >
        신규 학생 등록
      </a>
      <a href="javascript:;" class="comm__green_btn btn_m_size2 d_block wid130 commp ml10" @click="excel()">엑셀 다운로드</a>
    </v-list>
  </div>
</template>

<script>
import Management from '@/apis/Management';
import DetailedSearch from '@/components/common/DetailedSearch.vue';
import List from '@/components/common/List.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import excelDownload from '@/components/common/mixin/tableList/excelDownload';

export default {
  mixins: [excelDownload],
  data() {
    return {
      cmp: 'managementStudentFamdocYeonjegu',
      pager: {},
      table: {
        maxNum: 1,
        col: ['*'],
        th: [
          '번호',
          '검진 유형',
          '이름<br>(성별)',
          '생년월일',
          '학교<br/>(학년/반/번호)',
          '연락처',
          '개인정보<br>활용동의',
          '문진표',
          '병원 등록',
          'APP 가입',
          'APP 가입<br/>초기화',
          '정보 수정',
          '삭제',
        ],
        list: [],
      },
      abortController: null,
    };
  },
  computed: {
    ...mapState(['update']),
    ...mapState('user', ['districtCode', 'userOrganTargetType', 'adminOrganType']),
    ...mapGetters('user', ['districtCodeFirst']),
    ...mapGetters('business', ['endBusiness']),
    ...mapGetters('definedCode', ['valueToCode', 'codeToValueList']),
  },
  components: {
    'v-detailed-search': DetailedSearch,
    'v-list': List,
  },
  watch: {
    '$route.query'() {
      this.getList();
    },
    update() {
      this.getList();
    },
  },
  created() {
    if (['A', 'C'].indexOf(this.districtCodeFirst) !== -1) {
      this.table.th.splice(8, 0, '교육 이수');
    }
    if (this.districtCodeFirst === 'C') {
      this.table.th.splice(4, 0, '학교 구분');
    }
    if (this.userOrganTargetType === 'CT') {
      this.table.th.splice(1, 0, this.districtCodeFirst === 'C' ? '시군명' : ['I', 'J'].includes(this.districtCodeFirst) ? '교육지원청' : '보건소');
    }
    if (this.adminOrganType === 'AD') {
      this.table.th.push('관리자 기능');
    }
    this.getList();
  },
  methods: {
    ...mapActions('popup', ['popupOpen']),
    studentEnrollment() {
      if (this.endBusiness('bill')) {
        this.$alert('학생 등록이 마감되었습니다.', '알림', 'warning');
        return false;
      }
      this.popupOpen({ contents: 'managementStudentStudentEnrollment', params: { businessDivision: 'FY' } });
    },
    getList() {
      this.$store.commit('tableLoading', true);
      let form = {
        districtCode: this.districtCode,
        tabType: this.valueToCode('C0036', 'FY'),
        page: 1,
        size: 10,
      };
      for (const i in this.$route.query) {
        form[i] = this.$route.query[i];
      }
      this.abortController = new AbortController();
      Management.studentList(form, this.abortController.signal).then(res => {
        if (res && res.data.rt === 200) {
          this.table.maxNum = res.data.paging.totalElements - form.size * (res.data.paging.number - 1);
          this.table.list = this.codeToValueList(res.data.studentList);
          this.pager = res.data.paging;
        } else {
          alert('알 수 없는 오류가 발생했습니다. 관리자에게 문의해주세요. 오류 코드 : ' + res.data.rt);
        }
        this.$store.commit('tableLoading', false);
      });
    },
    search(searchData) {
      let query = {};
      for (const i in searchData) {
        if (typeof searchData[i] === 'object') {
          for (const j in searchData[i]) {
            if (searchData[i][j]) {
              query[j] = searchData[i][j];
            } else {
              delete query[j];
            }
          }
        } else {
          if (searchData[i]) {
            query[i] = searchData[i];
          } else {
            delete query[i];
          }
        }
      }
      this.abortController?.abort();
      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        this.$router.push({ query: query });
      } else {
        this.getList();
      }
    },
  },
};
</script>
