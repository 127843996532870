<template>
  <div class="section_con">
    <div class="row bill__set">
      <div class="bill_box2 inner--left row" style="width: 96%">
        <table class="comm__table bill__table">
          <tbody>
            <tr>
              <th v-if="data.title" rowspan="2" class="bg_blue">
                <p class="scommp fwb">{{ data.title }}</p>
              </th>
              <th v-for="item in data.th" :key="item" class="bg_blue">
                <p class="scommp fwb">{{ item }}</p>
              </th>
            </tr>
            <tr>
              <td v-for="(item, index) in data.td" :key="index">
                <p class="commp fwn c_black2">
                  <span v-if="item[1]" class="d_inblock icon"><img :src="require(`@/assets/images/common/ic_${item[1] && item[1].includes('won') ? 'won' : item[1]}.png`)" /></span>
                  {{ item[1] && item[1].includes('won') ? numberWithCommas(item[0]) + '원' + (item[1] === 'wonLoe' ? ' 이하' : '') : item[0] }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
};
</script>
