<template>
  <div class="d_inblock" :style="{ padding }">
    <div class="badge-container">
      <slot />
      <div v-if="on" class="badge" :style="{ top: top ? `${top}px` : 0, right: right ? `${right}px` : 0 }"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['on', 'top', 'right'],
  computed: {
    padding() {
      const horizontalPadding = this.top < 0 ? `${-this.top}px` : 0;
      const verticalPadding = this.right < 0 ? `${-this.right}px` : 0;
      return `${horizontalPadding} ${verticalPadding}`;
    },
  },
};
</script>

<style scoped>
.badge-container {
  position: relative;
  display: inline-block;
  overflow: visible;
  vertical-align: middle;
}
.badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  overflow: visible;
}
</style>
