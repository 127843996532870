<template>
  <fragment>
    <div class="titbox">
      <p class="commp3 sub_title fwb">다운로드</p>
    </div>
    <div class="sub__content form__ver_1 bg_white mt30">
      <div>다운로드 내용</div>
    </div>
  </fragment>
</template>

<script>
export default {};
</script>
