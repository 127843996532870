var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub__content dash__board mt30"},[_c('div',{staticClass:"dash_board_content"},[_c('div',{staticClass:"dash__comm_set row mt40"},[_c('v-status-box',{attrs:{"title":"현황보드","data":_vm.statusBoardData}}),(['A', 'C'].includes(_vm.districtCodeFirst))?_c('v-item-container',{attrs:{"button":"바로가기","title":"공지사항","toggle-label":_vm.getToggleData(_vm.faqAndNotice.values, false),"class-name":"dash__comm_box4 inner--left"},scopedSlots:_vm._u([_vm._l((_vm.faqAndNotice.values),function(info,key){return {key:key,fn:function(){return [_c('notice-box',{key:key,attrs:{"data":info.list,"title":info.title,"onrowclick":function (item) {
                info.title.includes('문의')
                  ? _vm.$router.push({ path: ("" + (info.rowLink)), query: { inquiryId: item.inquiryId } })
                  : info.title === '자주하는 질문'
                  ? _vm.$router.push({ path: ("" + (info.rowLink)), query: { faqId: item.faqId } })
                  : _vm.$router.push({ path: ("" + (info.rowLink)), query: { noticeBoardId: item.noticeBoardId } });
              },"options":info.options}})]},proxy:true}})],null,true)}):_c('v-list-box',{attrs:{"title":"공지사항","data":_vm.noticeList,"button":{
          label: '바로가기',
          action: function () {
            this$1.$router.push('/center/board/notice/list');
          },
        },"onrowclick":function (item) {
            this$1.$router.push({ path: '/center/board/notice/contents', query: { noticeBoardId: item.noticeBoardId } });
          },"options":{
          baseIcon: require('@/assets/images/common/ic_loudspeaker.png'),
          datapick: true,
          pickEffect: 'New',
          pickCond: function (item) {
            var target = Date.parse(item.regdate);
            return target > _vm.pastDate.getTime() && target < _vm.nowDate.getTime();
          },
        }}}),_c('v-manual-box',{attrs:{"title":"사용자 매뉴얼","data":_vm.manualList,"data-img":_vm.manualImg}})],1),_c('div',{staticClass:"dash__comm_set row mt40 wid100"},[_c('div',{staticClass:"dash__comm_box dash__comm_box2 wid100"},[_vm._m(0),_c('div',{staticClass:"board__con bg_white mt15"},[_c('div',{staticClass:"list__con"},[_c('div',{staticClass:"m_inner row"},[(_vm.billChart.list.length)?_c('GChart',{attrs:{"type":"ColumnChart","options":_vm.chartProps['options'],"data":_vm.chartData(_vm.billChart)}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.billChart.message)}})],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tit_row row"},[_c('p',{staticClass:"commp5 c_black2 fwb"},[_vm._v("월별 수검률")])])}]

export { render, staticRenderFns }