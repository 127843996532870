var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('div',{staticClass:"form_wrap_question_table mt20 top_container",class:{ pdfBlock: _vm.pdf, form_print_wrap: _vm.view },style:(_vm.view ? 'width: 760px' : '')},[_c('div',{staticClass:"question_th"},[_c('div',[_c('p',{staticClass:"commp c_black fwb"},[_vm._v("문항")])]),_c('div',[_c('p',{staticClass:"commp c_black fwb"},[_vm._v("질문 사항")])]),_c('div',[_c('p',{staticClass:"commp c_black fwb"},[_vm._v("체크 사항")])])])]),_vm._l((_vm.data),function(item,index){return _c('div',{key:item.id,staticClass:"form_wrap_question_table",class:{ pdfBlock: _vm.pdf, form_print_wrap: _vm.view },style:(_vm.view ? 'width: 760px' : '')},[_c('div',{staticClass:"question_td"},[_c('div',[_c('p',{staticClass:"commp c_black fwn"},[_vm._v(_vm._s(_vm.dataArray[index].num))])]),_c('div',[_c('p',{staticClass:"commp c_black fwn",domProps:{"innerHTML":_vm._s(item.data1.label)}})]),_c('div',[_c('div',{staticClass:"input_container",class:{
            comm_radio: item.data1.type === 'radio',
            check_set: item.data1.type === 'checkbox',
            three_block: item.data1.options.length === 3 || item.data1.options.length >= 5,
            four_block: item.data1.options.length === 4,
          }},_vm._l((item.data1.options),function(option){return _c('fragment',{key:option.id},[_c('div',{class:{ other_box: item.data2 && option.label === '기타' }},[_c('input',{staticClass:"blind",attrs:{"id":item.data1.id + '_' + option.id,"type":item.data1.type,"name":item.data1.name,"disabled":_vm.view},domProps:{"value":option.value,"checked":_vm.checked(option.value, item.data1.value)},on:{"change":function($event){
          var _obj;
_vm.STUDENTSURVEY_DATA_SET(( _obj = {}, _obj[item.data1.id] = parseInt($event.target.value), _obj ));
                  _vm.textarea($event, option.label);}}}),_c('label',{attrs:{"for":item.data1.id + '_' + option.id}},[_c('span',{staticClass:"icon"}),_c('span',{staticClass:"label_t fwn",class:{ commp: item.data1.type === 'radio', c_black: item.data1.type === 'radio' },domProps:{"innerHTML":_vm._s(option.label)}})]),(item.data2 && option.label === '기타')?_c('div',{staticClass:"textbox",class:{ hei35: !_vm.view }},[(_vm.view)?_c('div',{staticClass:"textarea_box wid100",class:{ read_only: !item.data1.value.includes(option.value) },attrs:{"id":item.data2.id},domProps:{"innerHTML":_vm._s(item.data2.value.replace(/(\n)/g, '<br>'))}}):_c('textarea',{staticClass:"textarea_box wid100",class:{ read_only: !item.data1.value.includes(option.value) },attrs:{"id":item.data2.id,"name":item.data2.name,"cols":"30","rows":"10","disabled":!item.data1.value.includes(option.value),"placeholder":item.data2.placeholder},domProps:{"value":item.data2.value},on:{"input":function($event){
                  var _obj;
return _vm.STUDENTSURVEY_DATA_SET(( _obj = {}, _obj[item.data2.id] = $event.target.value, _obj ))}}})]):_vm._e()])])}),1)])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }