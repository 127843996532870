var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub__content dash__board mt30"},[_c('div',{staticClass:"dash_board_content"},[_c('div',{staticClass:"dash__comm_set row mt40"},[(_vm.isStatusBoard)?_c('v-status-box',{attrs:{"title":"현황보드","data":_vm.statusBoardData,"label-data":_vm.statusBoardBoxLabel}}):_vm._e(),(_vm.districtCodeFirst === 'J')?_c('v-status-container',{staticClass:"dash__comm_box7 inner--left",attrs:{"title":_vm.statusByBusinessAndGrade.title,"toggle-label":_vm.statusByBusinessAndGrade.toggleLabel,"data":_vm.statusByBusinessAndGrade.data}}):_vm._e(),(['A', 'C'].includes(_vm.districtCodeFirst))?_c('v-item-container',{attrs:{"button":"바로가기","title":_vm.faqAndNotice.title,"toggle-label":_vm.getToggleData(_vm.faqAndNotice.values, false),"class-name":"dash__comm_box4 inner--left"},scopedSlots:_vm._u([_vm._l((_vm.faqAndNotice.values),function(info,key){return {key:key,fn:function(){return [_c('notice-box',{key:key,attrs:{"data":info.list,"title":info.title,"onrowclick":function (item) {
                info.title.includes('문의')
                  ? _vm.$router.push({ path: ("" + (info.rowLink)), query: { inquiryId: item.inquiryId } })
                  : info.title === '자주하는 질문'
                  ? _vm.$router.push({ path: ("" + (info.rowLink)), query: { faqId: item.faqId } })
                  : _vm.$router.push({ path: ("" + (info.rowLink)), query: { noticeBoardId: item.noticeBoardId } });
              },"options":info.options}})]},proxy:true}})],null,true)}):_c('v-list-box',{attrs:{"title":"공지사항","data":_vm.noticeList,"button":{
          label: '바로가기',
          action: function () {
            this$1.$router.push('/school/board/notice/list');
          },
        },"onrowclick":function (item) {
            this$1.$router.push({ path: '/school/board/notice/contents', query: { noticeBoardId: item.noticeBoardId } });
          },"options":{
          baseIcon: require('@/assets/images/common/ic_loudspeaker.png'),
          datapick: true,
          pickEffect: 'New',
          pickCond: function (item) {
            var target = Date.parse(item.regdate);
            return target > _vm.pastDate.getTime() && target < _vm.nowDate.getTime();
          },
        }}}),_c('v-manual-box',{attrs:{"title":"사용자 매뉴얼","data":_vm.manualList,"data-img":_vm.manualImg}})],1),_c('div',{staticClass:"dash__comm_set row mt30 wid100"},[_c('div',{staticClass:"dash__comm_box dash__comm_box2 wid100"},[_c('div',{staticClass:"tit_row row flex_space_between_container"},[_c('p',{staticClass:"commp5 c_black2 fwb"},[_vm._v(_vm._s(_vm.billChart.title))]),(_vm.districtCodeFirst === 'J')?_c('p',{staticClass:"commp c_black2"},[_vm._v("※ 학년별 수검 현황을 확인하고 싶다면 아래 필터를 이용해 주세요.")]):_vm._e()]),_c('div',{staticClass:"board__con bg_white mt15"},[_c('div',{staticClass:"list__con"},[_c('div',{staticClass:"m_inner row"},[(_vm.districtCodeFirst === 'J')?_c('div',{staticClass:"flex_space_between_container"},[_c('p',{staticClass:"commp c_gray3"},[_vm._v(_vm._s(!_vm.selectedGrade ? '※ 해당 학년의 반별 수검률을 확인할 수 있습니다.' : ''))]),_c('div',{staticClass:"flex_box"},[_vm._m(0),_c('div',{staticClass:"m__putbox wid180"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedGrade),expression:"selectedGrade"}],staticClass:"comm_select wid180",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedGrade=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getDashBoardData()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("전체")]),_vm._l((6),function(n){return _c('option',{key:n,domProps:{"value":n}},[_vm._v(_vm._s(n)+"학년")])})],2)])])]):_vm._e(),(_vm.billChart.list.length)?_c('GChart',{attrs:{"type":"ColumnChart","options":_vm.chartProps['options'],"data":_vm.chartData(_vm.billChart),"events":_vm.chartProps['events']}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.billChart.message)}})],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"flex_box chart_legend"},[_c('li',[_vm._v("수검")]),_c('li',[_vm._v("미수검")])])}]

export { render, staticRenderFns }