<template>
  <div class="popup__con_inner" style="max-height: none">
    <div class="privacy-wrap">
      <div class="policy-wrap">
        <p class="policy-tit">제 1조(목적)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">
              이 약관은 ㈜카이아이컴퍼니(이하 “회사”라 합니다)가 제공하는 덴티아이 서비스(이하 "서비스" 및 "덴티아이"라 함)의 이용과 관련하여 "회사"와 "회원"과의 권리, 의무 및 책임사항, 이용조건 및
              절차 등 기본적인 사항을 규정함을 목적으로 합니다.
            </p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 2조(용어의 정의)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">가. "회원"이라 함은 "회사"의 "서비스"에 접속하여 이 약관에 따라 "회사"와 "이용계약"을 체결하고 "회사"가 제공하는 "서비스"를 이용하는 고객을 말합니다.</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">나. “서비스”라 함은 구현되는 단말기(PC, 휴대형단말기 등의 유무선 장치를 포함)와 상관없이 “회원”이 이용할 수 있는 덴티아이와 관련 제반 서비스를 의미합니다.</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">다. "아이디(ID)"라 함은 "회원"의 식별과 "서비스" 이용을 위하여 "회원"이 정하고 "회사"가 승인하는 문자와 숫자의 조합을 의미합니다.</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">라. "비밀번호"라 함은 "회원"이 부여 받은 "아이디"와 일치되는 "회원"임을 확인하고 비밀보호를 위해 "회원" 자신이 정한 문자 또는 숫자의 조합을 의미합니다.</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">마. "이용계약"이라 함은 이 약관을 포함하여 서비스 이용과 관련하여 "회사"와 "회원 간에 체결하는 모든 계약을 말합니다.</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">바. "해지"라 함은 "회사" 또는 "회원"이 "이용계약"을 해약하는 것을 말합니다.</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">사. "이용자"라 함은 "회사"에서 제공하는 "서비스" 또는 관련 제반 서비스를 이용하는 "회원"을 말합니다.</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">
                아. “구강관리정보 서비스”란 “회사”가 회원에게 덴티아이에서 제공하는 구강검진정보에 대한 접근 제공 등을 통하여, 회원이 자신의 구강검진정보를 확인하고 구강건강을 관리하는데 도움을 주기
                위한 서비스를 말합니다.
              </p>
            </div>
          </li>
          <li class="list">
            <p class="list-txt">2. 이 약관에서 사용하는 용어 중 제 2조에서 정하지 아니한 것은 관계 법령 및 서비스별 안내에서 정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 3조(약관의 게시와 개정)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. “회사”는 이 약관의 내용을 “회원”이 쉽게 알 수 있도록 모바일 어플리케이션과 웹사이트에 게시합니다.</p>
            <p class="list-txt">
              2. "회사"는 "약관의 규제에 관한 법률", "정보통신망이용촉진 및 정보보호 등에 관한 법률(이하 "정보통신망법")" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
            </p>
            <p class="list-txt">
              3. "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일 전부터 적용일자 전 일까지 공지합니다.
            </p>
            <p class="list-txt">
              4. "회사"가 전항에 따라 개정약관을 공지 또는 통지하면서 "회원"에게 7일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 "회원"이
              명시적으로 거부의 의사표시를 하지 아니한 경우 "회원"이 개정약관에 동의한 것으로 봅니다.
            </p>
            <p class="list-txt">
              5. "회원"이 개정약관의 적용에 동의하지 않는 경우 "회사"는 개정 약관의 내용을 적용할 수 없으며, 이 경우 "회원"은 "이용계약"을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한
              사정이 있는 경우에 "회사"는 "이용계약"을 해지할 수 있습니다.
            </p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 4조(약관의 해석)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">
              1. "회사"는 개별 서비스에 대해서는 별도의 이용약관 및 정책(이하 서비스 별 이용약관 또는 정책)을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우에는 "서비스 별 이용약관 또는 정책"을
              우선하여 적용합니다.
            </p>
            <p class="list-txt">2. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 관계법령 또는 상관례에 따릅니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 5조("이용계약" 체결)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. "이용계약"은 "회원"이 되고자 하는 자(이하 가입신청자)가 약관의 내용에 대하여 동의를 한 다음 회원가입 신청을 함으로써 체결됩니다.</p>
            <p class="list-txt">
              2. 회원으로 가입하여 서비스를 이용하고자 하는 자는 Facebook/Google/Kakao/Naver/Apple 등의 소셜 서비스 ID 식별자 중 하나의 정보를 제공해야 합니다. 또한 서비스 이용을 위해 필요한 개인
              정보의 수집과 이용에 대한 동의를 받을 수 있습니다. 다만, 일부 서비스의 이용 신청에 대해서는 추가로 사용자 인증 및 동의 절차를 필요로 합니다.
            </p>
            <p class="list-txt">
              3. "회사"는 "가입신청자"의 신청에 대하여 "서비스" 이용을 승낙함을 원칙으로 합니다. 다만, "회사"는 다음 각 호에 해당하는 신청에 대하여는 사후에 "이용계약"을 해지할 수 있습니다.
            </p>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">가. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 "회사"의 회원 재가입 승낙을 얻는 경우에는 예외로 함.</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">나. 타인의 명의를 이용한 경우</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">다. 허위의 정보를 기재하거나, "회사"가 제시하는 내용을 기재하지 않은 경우</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">라. "회원"의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">마. 사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우</p>
            </div>
          </li>
          <li class="list">
            <p class="list-txt">4. "회사"는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.</p>
            <p class="list-txt">5. 제 2항과 제 3항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, "회사"는 원칙적으로 이를 가입신청자에게 알리도록 합니다.</p>
            <p class="list-txt">6. "이용계약"의 성립 시기는 "회사"가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 6조("회원" 정보의 변경)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">
              1. "회원"은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 소셜서비스 ID 식별자 등은 수정이 불가능합니다.
            </p>
            <p class="list-txt">2. "회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 등 기타 방법으로 "회사"에 대하여 그 변경사항을 알려야 합니다.</p>
            <p class="list-txt">3. 제2항의 변경사항을 "회사"에 알리지 않아 발생한 불이익에 대하여 "회사"는 책임지지 않습니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 7조(개인정보보호 의무)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. “회사”는 “정보통신망법” 등 관계 법령이 정하는 바에 따라 “회원”의 개인정보를 보호하기 위해 노력합니다.</p>
            <p class="list-txt">
              2. 개인정보의 보호 및 사용에 대해서는 관련법 및 “회사”의 개인정보취급방침이 적용됩니다. 다만, “회사”의 공식 사이트 이외의 링크된 사이트에서는 “회사”의 개인정보취급방침이 적용되지
              않습니다.
            </p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 8조(“회사”의 의무)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. “회사”는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 “서비스”를 제공하기 위하여 최선을 다하여 노력합니다.</p>
            <p class="list-txt">2. “회사”는 “회원”이 안전하게 “서비스”를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.</p>
            <p class="list-txt">3. “회사”는 서비스이용과 관련하여 발생하는 이용자의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.</p>
            <p class="list-txt">4. “회사”는 서비스이용과 관련하여 “회원”으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 9조(“회원”의 의무)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. “회원”은 다음 행위를 하여서는 안 됩니다.</p>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">가. 신청 또는 변경 시 허위내용의 등록</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">나. 타인의 정보도용</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">다. “회사”가 게시한 정보의 변경</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">라. “회사”가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">마. “회사”와 기타 제3자의 저작권 등 지적재산권에 대한 침해</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">바. “회사” 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">사. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 “서비스”에 공개 또는 게시하는 행위</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">아. 회사의 동의 없이 영리를 목적으로 “서비스”를 사용하는 행위</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">자. 기타 불법적이거나 부당한 행위</p>
            </div>
          </li>
          <li class="list">
            <p class="list-txt">
              2. "회원"은 회원가입 신청 또는 회원정보 변경 시 모든 사항을 사실에 근거하여 본인의 진정한 정보로 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 이와 관련된 모든 권리를 주장할 수
              없습니다.
            </p>
            <p class="list-txt">
              3. "회원"은 "회사"의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과에 대해 "회사"는 책임을 지지 않습니다. 또한 "회원"은 이와 같은 영업활동으로 "회사"가
              손해를 입은 경우, "회원"은 "회사"에 대해 손해배상의무를 지며, "회사"는 해당 "회원"에 대해 서비스 이용제한 및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.
            </p>
            <p class="list-txt">4. "회원"은 "회사"의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.</p>
            <p class="list-txt">5. "회원"은 "회사" 및 기타 제3자의 지적 재산권을 포함한 제반 권리를 침해하는 행위를 해서는 안 됩니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 10조(“서비스”의 제공 등)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. 회사는 회원에게 아래와 같은 서비스를 제공합니다.</p>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">가. (App) 검진 유형별 병원 찾기 서비스</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">나. (App) 검진 유형별 문진표 등록</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">다. (App) 구강 보건 교육 서비스</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">라. (App) 구강 검진 결과 통보서 조회</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">마. (App) 맞춤형 구강 건강 리포트 서비스</p>
            </div>
          </li>
          <li class="list">
            <div class="list-txt seq-txt">
              <p class="list-txt">바. 기타 “회사”가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 “회원”에게 제공하는 일체의 서비스</p>
            </div>
          </li>
          <li class="list">
            <p class="list-txt">2. “서비스”는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</p>
            <p class="list-txt">
              3. “회사”는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 “서비스”의 제공을 일시적으로 중단할 수 있습니다. 다만, “회사”가 사전에 통지할 수
              없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
            </p>
            <p class="list-txt">4. “회사”는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 11조("서비스"의 변경)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. "회사"는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 "서비스"를 변경할 수 있습니다.</p>
            <p class="list-txt">
              2. "회사"는 무료로 제공되는 서비스의 일부 또는 전부를 "회사"의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 "회원"에게 별도의 보상을
              하지 않습니다.
            </p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 12조(정보의 제공 및 광고의 게재)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. "회사"는 "서비스"를 운영함에 있어 각종 정보를 서비스화면에 게재하거나 문자메시지, 전자우편, 어플리케이션 Push 알림 등의 방법으로 "이용자"에게 제공할 수 있습니다.</p>
            <p class="list-txt">
              2. "회사"는 서비스의 운영과 관련하여 홈페이지, 서비스화면, 문자메시지, 전자우편, 어플리케이션 Push 알림 등에 광고 등을 게재할 수 있습니다. 다만, "회사"가 광고 등을 문자메시지, 전자우편,
              어플리케이션 Push 알림 등을 이용하여 발송하는 경우 수신 동의 여부를 확인한 후 수신 동의한 "이용자"에 한하여 이를 발송합니다.
            </p>
            <p class="list-txt">3. "이용자"는 "회사"가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.</p>
            <p class="list-txt">
              4. "이용자"가 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는 등의 방법으로 교신 또는 거래를 하는 것은 전적으로 "이용자"와 광고주 간의 문제입니다.
              만약 "이용자"와 광고주간에 문제가 발생할 경우에도 "이용자"와 광고주가 직접 해결하여야 하며, 이와 관련하여 “회사”가 광고주의 위법행위에 적극 가담하였다거나, 위법행위를 고의 또는 중과실로
              방치하였다는 등의 사정이 없는 한 "회사"는 어떠한 책임도 부담하지 않습니다.
            </p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 13조(계약 해제, 해지 등)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">
              1. 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 회사에 해지신청을 하거나 웹 사이트 및 모바일 어플리케이션에서 회원 탈퇴하기 버튼을 클릭하고 이에 따른 탈퇴 절차를 거침으로써
              이용계약을 해지할 수 있습니다. 회사는 관련법령 등에서 정하는 바에 따라 이를 즉시 처리하여야 합니다.
            </p>
            <p class="list-txt">2. “회원”이 계약을 해지할 경우, 관련법 및 개인정보취급방침에 따라 “회사”가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 “회원”의 모든 데이터는 소멸됩니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 14조(손해배상)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. 회사와 이용자는 서비스 이용과 관련하여 고의 또는 과실로 상대방에게 손해를 끼친 경우에는 이를 배상하여야 합니다.</p>
            <p class="list-txt">2. 단, 회사는 무료로 제공하는 서비스의 이용과 관련하여 개인정보보호정책에서 정하는 내용에 위반하지 않는 한 어떠한 손해도 책임을 지지 않습니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 15조(이용제한 등)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">
              1. "회사"는 "회원"이 이 약관의 의무를 위반하거나 "서비스"의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 "서비스" 이용을 단계적으로 제한할 수 있습니다.
            </p>
            <p class="list-txt">
              2. "회사"는 전항에도 불구하고, "저작권법" 및 "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및 운영방해, "정보통신망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한
              초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 "서비스" 이용을 통해 획득한 혜택 등은 모두 소멸되며, "회사"는 이에 대해
              별도로 보상하지 않습니다.
            </p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 16조(책임제한)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. “회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”를 제공할 수 없는 경우에는 “서비스” 제공에 관한 책임이 면제됩니다.</p>
            <p class="list-txt">2. “회사”는 “회원”의 귀책사유로 인한 “서비스” 이용의 장애에 대하여는 책임을 지지 않습니다.</p>
            <p class="list-txt">3. “회사”는 “회원”이 “서비스”와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</p>
            <p class="list-txt">4. “회사”는 “회원” 간 또는 “회원”과 제3자 상호간에 “서비스”를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.</p>
            <p class="list-txt">5. “회사”는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 17조(준거법 및 재판관할)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. “회사”와 “회원” 간 제기된 소송은 대한민국법을 준거법으로 합니다</p>
            <p class="list-txt">2. 서비스 이용과 관련하여 “회사”와 “회원” 사이에 분쟁이 발생한 경우, “회사”와 “회원”은 분쟁의 해결을 위해 성실히 협의합니다</p>
            <p class="list-txt">3. 제1항의 협의에서도 분쟁이 해결되지 않을 경우 양 당사자는 ”회원”의 주소 또는 거소를 관할하는 지방법원을 관할 법원으로 합니다.</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 18조(회사의 연락처)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. 상호 : 주식회사 카이아이컴퍼니</p>
            <p class="list-txt">2. 대표자 : 정호정</p>
            <p class="list-txt">3. 주소 : 대전광역시 유성구 테크노중앙로 55, 901-이호(관평동, 에이스타워)</p>
            <p class="list-txt">4. 대표전화 : 1670-2628</p>
            <p class="list-txt">5. 이메일 주소 : i-contact@kai-i.com</p>
          </li>
        </ul>
      </div>
      <div class="policy-wrap">
        <p class="policy-tit">제 19조(부칙)</p>
        <ul class="policy-list">
          <li class="list">
            <p class="list-txt">1. 본 약관은 2021. 04. 05일부터 시행됩니다.</p>
            <p class="list-txt">2. 공고일자 : 2021. 04. 05</p>
            <p class="list-txt">3. 시행일자 : 2021. 04. 05</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    Gitple('shutdown');
  },
};
</script>
