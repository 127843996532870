<template>
  <div id="header">
    <header>
      <div class="header__gnb">
        <div class="header__inner maxWidth"></div>
      </div>
      <!-- //header__gnb -->
    </header>
  </div>
</template>

<script>
export default {};
</script>
